<template>
  <el-row class="delivery-handle">
    <el-col :span="4" v-if="!isFull">
      <message :list="orderList" :index="orderIndex"></message>
    </el-col>
    <el-col :span="isFull ? 24 : 15">
      <handle
        :list="item.order_items"
        :orderIndex="orderIndex"
        :isFull="isFull"
        @nextOrder="handleOrderIndex"
        @isFull="handleIsFull"
        @updata="handleSearchOrder"
        @splice="handleSplice"
      ></handle>
    </el-col>
    <el-col :span="5" v-if="!isFull">
      <search @search="handleSearchOrder"></search>
      <list :list="orderList" @index="handleOrderIndex"></list>
    </el-col>
  </el-row>
</template>
<script>
import Message from "./components/Message";
import Handle from "./components/Handle";
import List from "./components/List";
import Search from "./components/Search";
export default {
  name: "delivery",
  components: { Message, Handle, List, Search },
  data() {
    return {
      orderList: [],
      orderIndex: 0,
      item: {},
      isFull: false,
    };
  },
  methods: {
    async handleSearchOrder(value) {
      const searchText = value ? value : localStorage.deliverSearchText;
      this.$tool.debounce(100).then(async () => {
        const res = await this.$store.dispatch(
          "getOrderNumOrExpressNumFindOrderList",
          {
            searchText,
          }
        );
        this.orderList = res.orders;
        this.orderIndex = 0;
        this.item = this.orderList[0];
      });
    },
    handleSplice(index) {
      this.orderList[this.orderIndex].order_items.splice(index, 1);
    },
    //切换订单列表index
    handleOrderIndex(index) {
      if (Number(index) >= this.orderList.length) {
        this.$message({
          type: "warning",
          message: "已经是最后一个订单",
        });
        return;
      }
      this.orderIndex = Number(index);
      this.item = this.orderList[index];
    },
    //是否全屏
    handleIsFull(bool) {
      this.isFull = bool;
    },
  },
  created() {},
};
</script>
<style lang="less" scoped>
.delivery-handle {
  width: 100%;
  &:last-child {
    margin-right: 0;
  }
}
</style>